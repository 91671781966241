@import '../../../../scss/theme-bootstrap';

.product-brief {
  .mpp-container &__picture-container {
    position: relative;
    width: 50%;
    @include breakpoint($landscape-up) {
      width: 100%;
    }
  }
  &__container {
    padding: 10px;
    text-align: center;
    margin: 0 auto;
    .mpp-container & {
      display: flex;
      margin: 40px auto 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include breakpoint($landscape-up) {
        margin: 0 auto;
        padding: 10px;
        flex-wrap: wrap;
        height: 100%;
        justify-content: unset;
      }
      .product-brief__cta-section {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex: 1;
        align-items: flex-end;
        .product-brief {
          &__add-to-bag {
            display: flex;
            width: 100%;
            flex-direction: column;
            .product__button--add-to-bag {
              width: 100%;
              .product__button--loading {
                height: 60px;
                line-height: 60px;
                width: 100%;
              }
              a.button {
                width: 100%;
                min-width: 0;
                line-height: normal;
                align-items: center;
                justify-content: center;
                min-height: 60px;
                display: flex;
                background-color: $color-black-v2;
                color: $color-white;
                border: none;
                text-transform: none;
                transition: background 0.3s, color 0.3s, border-color 0.3s;
                &:hover,
                &:active {
                  text-decoration: none;
                  background-color: $color-green-v2;
                }
                &.button--disabled {
                  background: $color-grey;
                  cursor: default;
                  border-color: $color-dark-black;
                  color: $white;
                }
              }
            }
            a.product-add-to-waitlist {
              &.button {
                width: 100%;
                min-width: 0;
                line-height: normal;
                align-items: center;
                justify-content: center;
                min-height: 60px;
                display: flex;
                background-color: $color-black-v2;
                color: $color-white;
                border: none;
                transition: background 0.3s, color 0.3s, border-color 0.3s;
                &:hover,
                &:active {
                  text-decoration: none;
                  background-color: $color-green-v2;
                }
              }
            }
            .product__inventory-status {
              margin-top: 0;
            }
          }
        }
      }
    }
    @include breakpoint($landscape-up) {
      position: relative;
      float: none;
    }
    .product-brief__picture-container {
      position: relative;
    }
    .product {
      &__image {
        &-link {
          float: none;
          width: auto;
          img {
            max-height: 300px;
            @include breakpoint($landscape-up) {
              max-height: 360px;
            }
          }
        }
        &--med {
          .slick-slide & {
            display: inline-block !important;
          }
          @include breakpoint($landscape-up) {
            display: inline-block;
          }
        }
        &--default {
          @include breakpoint($landscape-up) {
            position: relative;
          }
        }
        &--hover {
          padding-#{$rdirection}: 10px;
          .slick-slide & {
            display: none !important;
          }
          @include breakpoint($landscape-up) {
            display: inline-block;
            margin-top: 10px;
            top: 0;
            #{$ldirection}: 0;
            opacity: 0;
            transition: opacity 0.3s;
            position: absolute;
          }
        }
      }
    }
    &:hover {
      .product__image--hover {
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
      .product-brief__cta--quickshop-button-on-hover {
        @include breakpoint($landscape-up) {
          opacity: 1;
        }
      }
    }
    &.esearch-product {
      .mpp-container & {
        flex-direction: row;
        justify-content: center;
      }
    }
  }
  &__name {
    font-family: $font--text;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 1em;
    text-align: center;
    a {
      text-decoration: none;
    }
    @include breakpoint($landscape-up) {
      padding: 0 10px;
      text-align: center;
      min-height: 72px;
    }
    @include breakpoint($small-down) {
      padding-top: 0;
      a {
        text-decoration: none;
      }
      .product__image-link {
        width: 50%;
      }
    }
    @include breakpoint($medium-up) {
      clear: both;
    }
    .mpp-container & {
      text-align: $ldirection;
      padding-top: 0;
      @include breakpoint($landscape-up) {
        text-align: center;
      }
    }
  }
  &__subline {
    text-align: center;
    @include breakpoint($landscape-up) {
      width: 100%;
    }
    .mpp-container & {
      text-align: $ldirection;
      @include breakpoint($landscape-up) {
        text-align: center;
        min-height: 75px;
      }
    }
  }
  &__sku-shade-swatch {
    display: inline-block;
    margin-#{$rdirection}: 0.5em;
    padding: 0.75em;
  }
  &__details {
    @include breakpoint($small-down) {
      float: $ldirection;
      width: 50%;
      padding-#{$ldirection}: 10px;
    }
    @include breakpoint($landscape-up) {
      width: 52%;
      margin-#{$ldirection}: 92px;
      margin-top: 10px;
    }
    .button {
      @include breakpoint($small-down) {
        width: 100%;
        margin-top: 5%;
      }
      &.product__button {
        &--add-favorites {
          display: none;
        }
        &--add-to-bag {
          font-weight: bold;
        }
      }
    }
    @include breakpoint($landscape-up) {
      a {
        text-decoration: none;
      }
    }
  }
  &__cta--shop-now {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    // Hide by default on standards MPPs
    .mpp-container & {
      display: none;
      text-align: $ldirection;
      @include breakpoint($landscape-up) {
        text-align: center;
      }
    }
    @include breakpoint($landscape-up) {
      .esearch-product & {
        #{$ldirection}: 0;
      }
    }
    // Show by default on custom MPPs (users can hide it via CMS)
    .mpp-container.mpp-container--custom & {
      display: block;
    }
  }
  &__cta--quickshop-button-on-hover {
    opacity: 0;
    position: absolute;
    bottom: 8%;
    #{$ldirection}: 50%;
    z-index: 10;
    transition: opacity 0.3s;
    transform: translate(-50%, 0);
    .product-brief__quickshop-launch {
      display: inline-block;
    }
  }
  @include breakpoint($landscape-up) {
    a {
      text-decoration: none;
    }
  }
  &__price {
    font-family: $main-font-medium;
    font-weight: bold;
    text-align: center;
    @include breakpoint($landscape-up) {
      min-height: 25px;
    }
    .mpp-container & {
      text-align: $ldirection;
      @include breakpoint($landscape-up) {
        text-align: center;
      }
    }
  }
  &__description-container {
    width: 100%;
    margin: 10px auto;
    float: $ldirection;
    text-align: center;
    padding-bottom: 95px;
    position: relative;
    .mpp-container & {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 30px auto 0;
      width: 100%;
      height: auto;
      flex: 1 1 auto;
    }
    @include breakpoint($landscape-up) {
      width: 85%;
      padding-#{$ldirection}: 0;
      float: none;
    }
    a {
      text-decoration: none;
    }
    .product__button {
      &--add-to-bag {
        position: relative;
        bottom: 0;
        width: 100%;
        margin-top: 10px;
        text-align: center;
        .mpp-container & {
          text-align: $ldirection;
          @include breakpoint($landscape-up) {
            text-align: center;
          }
        }
      }
    }
    .product-full__product-price {
      text-align: $ldirection;
      width: 50%;
    }
    .product-full__review-wrapper {
      display: flex;
      justify-content: center;
      .mpp-container & {
        justify-content: flex-start;
        @include breakpoint($landscape-up) {
          justify-content: center;
        }
      }
    }
  }
}

.product-preorder {
  line-height: 1.25;
  text-align: $ldirection;
  margin-top: 5px;
  &__active-text,
  &__sold-text,
  &__tos-text {
    font-weight: normal;
    font-size: 14px;
    color: inherit;
    margin-bottom: 10px;
  }
}

.spp-sticky-add-to-bag {
  .product-preorder {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      &__shipment-info {
        display: none;
      }
    }
  }
}

.product-quickshop,
.product-grid__item {
  .product-preorder {
    @include breakpoint($landscape-up) {
      text-align: center;
    }
    &__shipment-info {
      display: none;
    }
  }
}

.preorder-text {
  color: $color-error;
  text-transform: normal;
}

.pre-order-message-display {
  padding: 5px 0;
  font-size: 14px;
}

.preorder-disabled {
  background-color: $color-gray;
  pointer-events: none;
  border: 1px solid $color-gray;
}

.preorder-faq-header {
  font-size: 25px;
  margin-top: 40px;
}
